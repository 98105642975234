//src/components/chat/Message.js
import React from 'react';
import { format } from 'date-fns';
import { FileText, Image, Film, Mic } from 'lucide-react';

import { apiService } from '../../services/api';

const Message = ({ message }) => {
    const isOutgoing = message.direction === 'outgoing';
    //get the base url

    //console.log('baseUrl-->', apiService.getBaseUrl());
    const baseUrl = apiService.getBaseUrl();

    const getStatusIcon = (status) => {
        switch (status) {
            case 'sent':
                return '✓';
            case 'delivered':
                return '✓✓';
            case 'read':
                return <span className="text-blue-500">✓✓</span>;
            case 'failed':
                return '⚠️';
            default:
                return '⌛';
        }
    };

    const renderMediaContent = () => {
        if (!message.content.mediaUrl) return null;
        // console.log('URL', `${baseUrl}/${message.content.mediaUrl}`);

        switch (message.content.mediaType) {
            case 'image':
                return (
                    <div className="mb-2 rounded-lg overflow-hidden">
                        <img
                            src={`${baseUrl}${message.content.mediaUrl}`}
                            alt="Media content"
                            className="max-w-60  rounded-lg"
                            loading="lazy"
                        />
                        <span>{message.content.caption || ''}</span>
                    </div>
                );
            case 'video':
                return (
                    <div className="mb-2">
                        <video
                            controls
                            className="max-w-60 rounded-lg"
                            preload="metadata"
                        >
                            <source src={`${baseUrl}${message.content.mediaUrl}`} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <span>{message.content.caption || ''}</span>
                    </div>
                );
            case 'audio':
                return (
                    <div className="mb-2 flex items-center space-x-2">
                        <Mic className="w-4 h-4" />
                        <audio controls className="max-w-sm" preload="none">
                            <source src={`${baseUrl}${message.content.mediaUrl}`} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                );
            case 'document':
                return (
                    <div>
                        <a
                            href={`${baseUrl}${message.content.mediaUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mb-2 flex items-center space-x-2 text-white-600 hover:text-white-800"
                        >
                            <FileText className="w-4 h-4" />
                            <span>Download</span>
                        </a>
                        <span>{message.content.caption || ''}</span>
                    </div>

                );
            default:
                return null;
        }
    };

    return (
        <div className={`flex ${isOutgoing ? 'justify-end' : 'justify-start'}`}>
            <div
                className={`
          max-w-[70%] rounded-lg px-4 py-2 shadow-sm
          ${isOutgoing ? 'bg-indigo-600 text-white' : 'bg-white text-gray-900'}
        `}
            >
                {/* Media Content */}
                {renderMediaContent()}

                {/* Message Text */}
                {message.content.text && (
                    <p className="whitespace-pre-wrap break-words">
                        {message.content.text}
                    </p>
                )}

                {/* Message Meta */}
                <div className={`
          flex items-center justify-end space-x-2 mt-1
          ${isOutgoing ? 'text-indigo-200' : 'text-gray-500'}
          text-xs
        `}>
                    <span>
                        {format(new Date(message.timestamp), 'HH:mm')}
                    </span>
                    {isOutgoing && (
                        <span className="ml-1">
                            {getStatusIcon(message.status)}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Message;