// src/components/dashboard/MetricsDashboard.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/api';
import { formatDuration } from '../../utils/dashboardUtils';
import DateRangePicker from './DateRangePicker';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import {
    MessageSquare,
    Users,
    Clock,
    CheckCircle,
    RefreshCcw
} from 'lucide-react';
import { subDays } from 'date-fns';
import { format } from 'date-fns';

const MetricsDashboard = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [metrics, setMetrics] = useState({
        messageVolume: { total: 0, growth: '0%', hourlyDistribution: [] },
        activeUsers: { total: 0, avgMessagesPerUser: 0 },
        responseTime: { average: 0, median: 0 },
        retention: { current: 0, lastWeek: 0, weeklyRates: [] }
    });

    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }
        refreshDashboard();
    }, [user]);

    const refreshDashboard = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await apiService.getEngagementMetrics(
                format(startDate, 'yyyy-MM-dd'),
                format(endDate, 'yyyy-MM-dd HH:mm:ss')
            );

            const data = response.data;

            // Create a dummy user distribution since it's not available in the API
            const dummyUserDistribution = [
                {
                    name: 'Total Users',
                    messageCount: data.totalMessages
                }
            ];

            setMetrics({
                messageVolume: {
                    total: data.totalMessages,
                    hourlyDistribution: data.messageFrequency.hourlyDistribution,
                    averagePerHour: data.messageFrequency.averagePerHour
                },
                activeUsers: {
                    total: data.activeUsers,
                    avgMessagesPerUser: data.averageMessagesPerUser,
                    // Use dummy distribution or empty array if data not available
                    userDistribution: dummyUserDistribution
                },
                responseTime: {
                    average: data.responsiveness.averageResponseTime,
                    median: data.responsiveness.medianResponseTime,
                    percentiles: {
                        p90: data.responsiveness.responseTimePercentiles.p90,
                        p95: data.responsiveness.responseTimePercentiles.p95
                    },
                    trend: [] // Since trend data isn't available in the API response
                },
                retention: {
                    current: data.userRetention.averageRetention,
                    weeklyRates: data.userRetention.weeklyRates || [],
                    lastWeek: data.userRetention.weeklyRates?.[0]?.rate || 0
                },
                messageTypes: {
                    text: data.totalMessages, // Since we don't have message type breakdown
                    image: 0,
                    video: 0,
                    document: 0,
                    audio: 0
                }
            });
        } catch (err) {
            setError('Failed to load metrics data');
            console.error('Metrics dashboard error:', err);
        } finally {
            setLoading(false);
        }
    };


    const hourlyActivityHeatmapData = {
        labels: Array.from({ length: 7 }, (_, i) =>
            ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][i]
        ),
        datasets: [{
            label: 'Activity Intensity',
            data: metrics.messageVolume.hourlyDistribution,
            backgroundColor: (context) => {
                const value = context.dataset.data[context.dataIndex];
                const maxValue = Math.max(...metrics.messageVolume.hourlyDistribution);
                const intensity = value / maxValue;
                return `rgba(59, 130, 246, ${intensity})`;
            },
            borderWidth: 1,
            borderColor: '#fff'
        }]
    };

    const hourlyActivityHeatmapOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (context) => `Messages: ${context.raw}`
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Day of Week'
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Hour of Day'
                }
            }
        }
    };

    // Messages per User Distribution
    const messagesPerUserData = {
        labels: metrics.activeUsers.userDistribution?.map(user => user.name || user.phone) || [],
        datasets: [{
            label: 'Messages Sent',
            data: metrics.activeUsers.userDistribution?.map(user => user.messageCount) || [],
            backgroundColor: 'rgba(16, 185, 129, 0.7)',
            borderColor: 'rgb(16, 185, 129)',
            borderWidth: 1
        }]
    };

    const messagesPerUserOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Messages'
                }
            }
        }
    };

    // Message Type Distribution
    const messageTypeData = {
        labels: ['Text', 'Image', 'Video', 'Document', 'Audio'],
        datasets: [{
            data: [
                metrics.messageTypes?.text || 0,
                metrics.messageTypes?.image || 0,
                metrics.messageTypes?.video || 0,
                metrics.messageTypes?.document || 0,
                metrics.messageTypes?.audio || 0
            ],
            backgroundColor: [
                'rgba(59, 130, 246, 0.7)',  // Blue
                'rgba(16, 185, 129, 0.7)',  // Green
                'rgba(249, 115, 22, 0.7)',  // Orange
                'rgba(147, 51, 234, 0.7)',  // Purple
                'rgba(236, 72, 153, 0.7)'   // Pink
            ]
        }]
    };

    const messageTypeOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right'
            }
        }
    };

    // Response Time Trend
    const responseTimeTrendData = {
        labels: metrics.responseTime.trend?.map(point => format(new Date(point.timestamp), 'HH:mm')) || [],
        datasets: [{
            label: 'Response Time',
            data: metrics.responseTime.trend?.map(point => point.duration) || [],
            borderColor: 'rgb(147, 51, 234)',
            backgroundColor: 'rgba(147, 51, 234, 0.1)',
            fill: true
        }]
    };

    const responseTimeTrendOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => `Response Time: ${formatDuration(context.raw)}`
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (value) => formatDuration(value)
                }
            }
        }
    };


    const messageVolumeChartData = {
        labels: Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`),
        datasets: [{
            label: 'Message Volume',
            data: metrics.messageVolume.hourlyDistribution,
            borderColor: 'rgb(59, 130, 246)',
            backgroundColor: 'rgba(59, 130, 246, 0.1)',
            fill: true,
            tension: 0.4
        }]
    };

    const messageVolumeOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 10,
                    callback: (value) => value
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                }
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45
                }
            }
        }
    };

    const activityPatternChartData = {
        labels: Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`),
        datasets: [{
            label: 'Activity Level',
            data: metrics.messageVolume.hourlyDistribution,
            backgroundColor: 'rgba(16, 185, 129, 0.7)',
            borderColor: 'rgb(16, 185, 129)',
            borderWidth: 1
        }]
    };

    const activityPatternOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `Messages: ${context.raw}`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 10,
                    callback: (value) => value
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                }
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45
                }
            }
        }
    };

    const retentionChartData = {
        labels: metrics.retention.weeklyRates.map(w => `Week ${w.week}`),
        datasets: [{
            label: 'Retention Rate',
            data: metrics.retention.weeklyRates.map(w => w.rate),
            borderColor: 'rgb(249, 115, 22)',
            backgroundColor: 'rgba(249, 115, 22, 0.1)',
            fill: true,
            tension: 0.4
        }]
    };

    const retentionChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `Retention: ${context.raw}%`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    callback: value => `${value}%`,
                    stepSize: 20
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                }
            },
            x: {
                grid: {
                    display: false,
                }
            }
        }
    };


    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };

    const responseTimeChartData = {
        labels: ['Median', 'Average', '90th Percentile', '95th Percentile'],
        datasets: [{
            label: 'Response Time',
            data: [
                metrics.responseTime.median,
                metrics.responseTime.average,
                metrics.responseTime?.percentiles?.p90 || 0,
                metrics.responseTime?.percentiles?.p95 || 0
            ],
            backgroundColor: [
                'rgba(147, 51, 234, 0.7)',  // Purple for median
                'rgba(147, 51, 234, 0.5)',  // Lighter purple for average
                'rgba(147, 51, 234, 0.3)',  // Even lighter for p90
                'rgba(147, 51, 234, 0.2)'   // Lightest for p95
            ],
            borderColor: 'rgb(147, 51, 234)',
            borderWidth: 1
        }]
    };

    const responseTimeOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `Time: ${formatDuration(context.raw)}`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                },
                ticks: {
                    callback: function (value) {
                        return formatDuration(value);
                    }
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45
                }
            }
        }
    };

    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Header */}
            <header className="bg-white shadow sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
                        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                            Engagement Metrics Dashboard
                        </h1>

                        <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                onStartChange={(date) => {
                                    setStartDate(date);
                                    setError(null);
                                }}
                                onEndChange={(date) => {
                                    setEndDate(date);
                                    setError(null);
                                }}
                            />

                            <button
                                onClick={refreshDashboard}
                                disabled={loading}
                                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
                            >
                                <RefreshCcw size={16} className={loading ? 'animate-spin' : ''} />
                                Refresh
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Error Display */}
            {error && (
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        {error}
                        <button
                            onClick={() => setError(null)}
                            className="absolute right-0 top-0 p-4"
                        >
                            ×
                        </button>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <main className=" py-6 px-4 sm:px-6 overflow-auto">
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                    </div>
                ) : (
                    <div className="max-w-7xl mx-auto space-y-6">
                        {/* Metric Cards */}
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                            <MetricCard
                                title="Total Messages"
                                value={metrics.messageVolume.total.toLocaleString()}
                                subValue={metrics.messageVolume.growth}
                                icon={<MessageSquare className="text-blue-500" />}
                            />
                            <MetricCard
                                title="Active Users"
                                value={metrics.activeUsers.total.toLocaleString()}
                                subValue={`${metrics.activeUsers.avgMessagesPerUser.toFixed(1)} avg/user`}
                                icon={<Users className="text-green-500" />}
                            />
                            <MetricCard
                                title="Avg Response Time"
                                value={formatDuration(metrics.responseTime.average)}
                                subValue={`${formatDuration(metrics.responseTime.median)} median`}
                                icon={<Clock className="text-purple-500" />}
                            />
                            <MetricCard
                                title="User Retention"
                                value={`${metrics.retention.current}%`}
                                subValue={`${metrics.retention.lastWeek}% last week`}
                                icon={<CheckCircle className="text-orange-500" />}
                            />
                        </div>

                        {/* Charts */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <ChartCard title="Message Volume Trend">
                                <Line data={messageVolumeChartData} options={chartOptions} />
                            </ChartCard>
                            <ChartCard title="Daily Activity Pattern">
                                <Bar data={activityPatternChartData} options={chartOptions} />
                            </ChartCard>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <ChartCard title="User Retention by Week">
                                <Line data={retentionChartData} options={{
                                    ...chartOptions,
                                    scales: {
                                        ...chartOptions.scales,
                                        y: {
                                            ...chartOptions.scales.y,
                                            max: 100,
                                            ticks: {
                                                callback: value => `${value}%`
                                            }
                                        }
                                    }
                                }} />
                            </ChartCard>
                            <ChartCard title="Response Time Distribution">
                                <Bar data={responseTimeChartData} options={responseTimeOptions} />
                            </ChartCard>

                            {/* Additional chart can be added here */}

                            <ChartCard title="Message Types Distribution">
                                <Doughnut data={messageTypeData} options={messageTypeOptions} />
                            </ChartCard>
                            <ChartCard title="Hourly Activity Heatmap">
                                <Bar data={hourlyActivityHeatmapData} options={hourlyActivityHeatmapOptions} />
                            </ChartCard>

                        </div>
                    </div>
                )}
            </main>
        </div>
    );
};

// Metric Card Component
const MetricCard = ({ title, value, subValue, icon }) => (
    <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center justify-between">
            <div>
                <p className="text-sm font-medium text-gray-600">{title}</p>
                <p className="mt-2 text-3xl font-semibold text-gray-900">{value}</p>
                <p className="mt-1 text-sm text-gray-500">{subValue}</p>
            </div>
            <div className="p-3 bg-gray-50 rounded-full">{icon}</div>
        </div>
    </div>
);

// Chart Card Component
const ChartCard = ({ title, children }) => (
    <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">{title}</h3>
        <div className="h-[300px]">
            {children}
        </div>
    </div>
);

export default MetricsDashboard;