
//src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { apiService } from '../services/api';
import { socketService } from '../services/socket';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    /*
    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const data = await apiService.validateToken(token);
                    if (data?.user) {
                        let updatedUser = data.user;
                        // Add token to user object
                        updatedUser.token = token;
                        setUser(updatedUser.user);
                        // Initialize socket connection with the token
                        try {
                            socketService.connect(token);
                        } catch (err) {
                            console.error('Socket connection error:', err);
                        }
                    } else {
                        localStorage.removeItem('token');
                    }
                } catch (err) {
                    console.error('Auth validation error:', err);
                    localStorage.removeItem('token');
                }
            }
            setLoading(false);
        };

        checkAuth();

        // Cleanup function
        return () => {
            socketService.disconnect();
        };
    }, []);
    */

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    // You could validate the token here with your backend
                    //validateToken
                    const data = await apiService.validateToken(token);
                    //console.log('TokenValidted:', data);
                    //setUser(data?.user);
                    let updatedUser = data.user;
                    // Add token to user object
                    updatedUser.token = token;
                    setUser(updatedUser);
                } catch (err) {
                    console.error('Auth validation error:', err);
                    localStorage.removeItem('token');
                }
            }
            setLoading(false);
        };

        checkAuth();
    }, []);

    const login = async (username, password) => {
        try {
            setError(null);
            // Use apiService instead of direct fetch
            const data = await apiService.login(username, password);
            console.log('data:', data);

            // Store token and user data
            localStorage.setItem('token', data.token);
            let updatedUser = data.user;
            // Add token to user object
            updatedUser.token = data.token;
            setUser(updatedUser);
            //store role
            localStorage.setItem('role', data?.user?.role);
            //username
            localStorage.setItem('username', data?.user?.username);
            //email
            localStorage.setItem('email', data?.user?.email);
            return data;
        } catch (err) {
            setError(err.message || 'Login failed');
            throw err;
        }
    };

    const logout = async () => {
        try {
            // Use apiService for logout
            await apiService.logout();
        } catch (err) {
            console.error('Logout error:', err);
        } finally {
            // Always clear local data
            localStorage.removeItem('token');
            setUser(null);
        }
    };

    const value = {
        user,
        loading,
        error,
        login,
        logout,
        isAuthenticated: !!user
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Hook for consuming the auth context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;

