//src/components/chat/MessageList.js
import React, { useEffect, useRef } from 'react';
import Message from './Message';

const formatDate = (dateString) => {
    try {
        if (!dateString) return 'Unknown Date';
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateString);
            return 'Invalid Date';
        }

        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        const compareDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const compareToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const compareYesterday = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());

        if (compareDate.getTime() === compareToday.getTime()) {
            return 'Today';
        } else if (compareDate.getTime() === compareYesterday.getTime()) {
            return 'Yesterday';
        }
        return new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }).format(date);
    } catch (error) {
        console.error('Date formatting error:', error);
        return 'Unknown Date';
    }
};

const MessageList = ({ messages, onLoadMore, goToBottom, initialLoading, loading }) => {
    const containerRef = useRef(null);
    const observerRef = useRef(null);
    const firstMessageRef = useRef(null);
    const previousHeightRef = useRef(0);
    const isLoadingRef = useRef(false);
    const lastScrollPositionRef = useRef(0);

    // Maintain scroll position when new messages are loaded at the top
    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        if (goToBottom) {
            // Scroll to bottom for new messages
            container.scrollTop = container.scrollHeight;
        } else if (loading) {
            // Save the current scroll height before loading more messages
            previousHeightRef.current = container.scrollHeight;
        } else if (previousHeightRef.current) {
            // Restore scroll position after loading more messages
            const newPosition = container.scrollHeight - previousHeightRef.current;
            if (newPosition > 0) {
                container.scrollTop = newPosition;
                previousHeightRef.current = 0;
            }
        }
    }, [messages, goToBottom, loading]);

    // Setup intersection observer for infinite scroll
    useEffect(() => {
        if (!onLoadMore || initialLoading || loading) return;

        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 0.1
        };

        const handleIntersect = async (entries) => {
            const [entry] = entries;
            if (entry.isIntersecting && !isLoadingRef.current) {
                const container = containerRef.current;
                if (!container) return;

                // Save current scroll position
                lastScrollPositionRef.current = container.scrollTop;

                // Set loading flag
                isLoadingRef.current = true;

                // Load more messages
                await onLoadMore();

                // Reset loading flag after a short delay
                setTimeout(() => {
                    isLoadingRef.current = false;
                }, 1000); // Prevent rapid consecutive loads
            }
        };

        const observer = new IntersectionObserver(handleIntersect, options);
        observerRef.current = observer;

        if (firstMessageRef.current) {
            observer.observe(firstMessageRef.current);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [onLoadMore, initialLoading, loading]);

    const groupMessagesByDate = (messages) => {
        const groups = {};
        messages.forEach(message => {
            const messageDate = new Date(message.timestamp);
            if (isNaN(messageDate.getTime())) {
                console.error('Invalid message date:', message.timestamp);
                return;
            }

            const dateKey = messageDate.toISOString().split('T')[0];
            if (!groups[dateKey]) {
                groups[dateKey] = [];
            }
            groups[dateKey].push(message);
        });
        return groups;
    };

    const messageGroups = groupMessagesByDate(messages);

    return (
        <div
            ref={containerRef}
            className="flex-1 overflow-y-auto px-4 py-6 space-y-6 bg-gray-50"
        >
            {loading && (
                <div className="flex justify-center py-2">
                    <div className="w-6 h-6 border-2 border-gray-300 border-t-indigo-600 rounded-full animate-spin" />
                </div>
            )}

            {Object.entries(messageGroups).map(([dateKey, groupMessages], groupIndex) => (
                <div key={dateKey}>
                    <div className="flex items-center justify-center mb-4">
                        <div className="bg-gray-200 rounded-full px-4 py-1">
                            <span className="text-xs text-gray-600">
                                {formatDate(dateKey)}
                            </span>
                        </div>
                    </div>
                    <div className="space-y-4">
                        {groupMessages.map((message, messageIndex) => {
                            const isFirstMessage = groupIndex === 0 && messageIndex === 0;
                            return (
                                <div
                                    key={message.messageId}
                                    ref={isFirstMessage ? firstMessageRef : null}
                                >
                                    <Message message={message} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MessageList;

















