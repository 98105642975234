// src/components/dashboard/charts/index.js

import React from 'react';
import { Line, Doughnut, Bar } from 'react-chartjs-2';

export const MessageVolumeChart = ({ data }) => {
    if (!data || !data.length) return null;

    const chartData = {
        labels: data.map(item => item._id),
        datasets: [
            {
                label: 'Incoming',
                data: data.map(item =>
                    item.messages.find(m => m.direction === 'incoming')?.count || 0
                ),
                borderColor: 'rgb(59, 130, 246)',
                tension: 0.1,
                fill: false
            },
            {
                label: 'Outgoing',
                data: data.map(item =>
                    item.messages.find(m => m.direction === 'outgoing')?.count || 0
                ),
                borderColor: 'rgb(16, 185, 129)',
                tension: 0.1,
                fill: false
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            },
            x: {
                grid: {
                    display: false
                }
            }
        }
    };

    return <Line data={chartData} options={options} />;
};

export const MessageTypeChart = ({ data }) => {
    if (!data || !data.length) return null;

    const chartData = {
        labels: data.map(type => type.type.replace('_message', '')),
        datasets: [{
            data: data.map(type => type.count),
            backgroundColor: [
                'rgb(59, 130, 246)',
                'rgb(16, 185, 129)',
                'rgb(251, 146, 60)',
                'rgb(147, 51, 234)',
                'rgb(236, 72, 153)',
                'rgb(124, 58, 237)'
            ],
            borderWidth: 1
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right'
            }
        }
    };

    return <Doughnut data={chartData} options={options} />;
};

export const SentimentChart = ({ data }) => {
    if (!data || !data.overall) return null;

    const chartData = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [{
            label: 'Sentiment Distribution',
            data: [
                data.overall.positive,
                data.overall.neutral,
                data.overall.negative
            ],
            backgroundColor: [
                'rgb(16, 185, 129)',
                'rgb(59, 130, 246)',
                'rgb(239, 68, 68)'
            ]
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return <Bar data={chartData} options={options} />;
};

/*
export const ActivityPatternChart = ({ data }) => {
    if (!data || !data.interactionPatterns) return null;

    const hourLabels = Array.from({ length: 24 }, (_, i) =>
        `${String(i).padStart(2, '0')}:00`
    );

    let hourlyData = Array(24).fill(0);
    if (data.interactionPatterns.hourlyDistribution) {
        hourlyData = data.interactionPatterns.hourlyDistribution;
    }

    const chartData = {
        labels: hourLabels,
        datasets: [{
            label: 'Message Volume',
            data: hourlyData,
            borderColor: 'rgb(147, 51, 234)',
            backgroundColor: 'rgba(147, 51, 234, 0.1)',
            fill: true,
            tension: 0.4
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return `Time: ${context[0].label}`;
                    },
                    label: function (context) {
                        return `Messages: ${context.raw}`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45
                }
            }
        }
    };

    return <Line data={chartData} options={options} />;
};
*/