import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import AdminLayout from './components/layouts/AdminLayout';
import AgentChat from './components/chat/AgentChat';
import LoginForm from './components/auth/LoginForm';
import Loading from './components/shared/Loading';
import WhatsAppDashboard from './components/dashboard/WhatsAppDashboard';
import MetricsDashboard from './components/dashboard/MetricsDashboard';
import SentimentDashboard from './components/dashboard/SentimentDashboard';
import UserManagement from './components/dashboard/UserManagement';

// Protected Route with Layout Component
const ProtectedRoute = ({ children, layout: Layout = React.Fragment, requiredRole }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading fullScreen />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  //console.log('user.role-->:', user.role);
  if (requiredRole && user.role !== requiredRole) {
    return <Navigate to="/" />;
  }

  return <Layout>{children}</Layout>;
};

// Public Route Component
const PublicRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading fullScreen />;
  }

  if (user) {
    return <Navigate to="/" />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/login"
            element={
              <PublicRoute>
                <LoginForm />
              </PublicRoute>
            }
          />

          {/* Admin Routes */}
          <Route
            path="/admin/dashboard/overview"
            requiredRole='admin'
            element={
              <ProtectedRoute layout={AdminLayout}>
                <WhatsAppDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/dashboard/metrics"
            requiredRole='admin'
            element={
              <ProtectedRoute layout={AdminLayout}>
                <MetricsDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/dashboard/sentiment"
            element={
              <ProtectedRoute layout={AdminLayout}>
                <SentimentDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/users"
            element={
              <ProtectedRoute layout={AdminLayout} requiredRole="admin">
                <UserManagement />
              </ProtectedRoute>
            }
          />

          {/* Chat Route */}
          <Route
            path="/"
            element={
              <ProtectedRoute layout={AdminLayout}>
                <AgentChat />
              </ProtectedRoute>
            }
          />

          {/* Catch all route */}
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>

        {/* Toast Container */}
        <div
          id="toast-container"
          className="fixed top-4 right-4 z-50 flex flex-col gap-2"
        />
      </Router>
    </AuthProvider>
  );
}

export default App;