// src/components/dashboard/DateRangePicker.js
import React from 'react';
import DatePicker from 'react-datepicker';
import { Calendar } from 'lucide-react';

const CustomDatePicker = ({ selected, onChange, isStart, ...props }) => (
    <div className="relative">
        <DatePicker
            selected={selected}
            onChange={onChange}
            {...props}
            className="
                w-full pl-10 pr-4 py-2
                bg-white border border-gray-300 
                rounded-lg shadow-sm
                text-gray-900 text-sm
                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                hover:border-gray-400
                transition-colors duration-200
            "
        />
        <Calendar
            size={18}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
        />
    </div>
);

const DateRangePicker = ({ startDate, endDate, onStartChange, onEndChange }) => (
    <div className="flex items-center gap-3">
        <CustomDatePicker
            selected={startDate}
            onChange={onStartChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            placeholderText="Start Date"
            dateFormat="yyyy-MM-dd"
        />

        <span className="text-gray-500 font-medium">to</span>

        <CustomDatePicker
            selected={endDate}
            onChange={onEndChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            placeholderText="End Date"
            dateFormat="yyyy-MM-dd"
        />
    </div>
);

export default DateRangePicker;