import React from 'react';

const UploadProgressBar = ({ progress, fileName }) => {
    // Format file name to show only first 20 characters + extension if too long
    const formatFileName = (name) => {
        if (name.length <= 20) return name;
        const extension = name.split('.').pop();
        return `${name.substring(0, 20)}...${extension}`;
    };

    // Determine color based on progress
    const getProgressColor = (progress) => {
        if (progress === 100) return 'bg-green-600';
        if (progress < 30) return 'bg-red-600';
        if (progress < 70) return 'bg-yellow-600';
        return 'bg-indigo-600';
    };

    return (
        <div className="fixed bottom-24 right-4 bg-white rounded-lg shadow-lg p-4 w-80 border border-gray-200">
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2 truncate">
                    {progress === 100 ? (
                        <span className="w-4 h-4 text-green-500">✓</span>
                    ) : (
                        <div className="w-4 h-4 border-2 border-indigo-600 border-t-transparent rounded-full animate-spin" />
                    )}
                    <span className="text-sm font-medium text-gray-700 truncate" title={fileName}>
                        {formatFileName(fileName)}
                    </span>
                </div>
                <span className="text-sm font-medium text-gray-500">
                    {Math.round(progress)}%
                </span>
            </div>

            <div className="w-full bg-gray-200 rounded-full h-2 overflow-hidden">
                <div
                    className={`h-full rounded-full transition-all duration-300 ease-out ${getProgressColor(progress)}`}
                    style={{
                        width: `${progress}%`,
                        transition: 'width 0.3s ease-out'
                    }}
                />
            </div>
        </div>
    );
};

export default UploadProgressBar;