
//src/services/socket.js
import { io } from 'socket.io-client';

class SocketService {
    constructor() {
        this.socket = null;
        this.eventHandlers = new Map();
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
    }

    // Initialize socket connection
    connect(token) {
        console.log("services/socket.js", 'Connecting socket...');
        if (this.socket?.connected) {
            console.log('Socket already connected');
            return this.socket;
        }

        const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || ''
        console.log("services/socket.js", SOCKET_URL);
        if (!SOCKET_URL) {
            throw new Error('Socket URL not configured');
        }

        this.socket = io(SOCKET_URL, {
            auth: { token },
            transports: ['websocket'],
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: this.maxReconnectAttempts,
        });

        // Set up default event handlers
        this.setupDefaultHandlers();

        return this.socket;
    }

    // Setup default socket event handlers
    setupDefaultHandlers() {
        if (!this.socket) return;

        this.socket.on('connect', () => {
            console.log('Socket connected');
            this.reconnectAttempts = 0;
        });

        this.socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
            this.reconnectAttempts++;

            if (this.reconnectAttempts >= this.maxReconnectAttempts) {
                this.handleReconnectFailure();
            }
        });

        this.socket.on('disconnect', (reason) => {
            console.log('Socket disconnected:', reason);

            if (reason === 'io server disconnect') {
                // Disconnected by server, attempt reconnection
                this.socket.connect();
            }
        });

        this.socket.on('error', (error) => {
            console.error('Socket error:', error);
        });
    }

    // Subscribe to an event
    on(event, handler) {
        if (!this.socket) {
            throw new Error('Socket not initialized');
        }

        // Store handler reference for cleanup
        if (!this.eventHandlers.has(event)) {
            this.eventHandlers.set(event, new Set());
        }
        this.eventHandlers.get(event).add(handler);

        this.socket.on(event, handler);
    }

    // Unsubscribe from an event
    off(event, handler) {
        if (!this.socket) return;

        if (handler) {
            this.socket.off(event, handler);
            this.eventHandlers.get(event)?.delete(handler);
        } else {
            this.socket.off(event);
            this.eventHandlers.delete(event);
        }
    }

    // Emit an event
    emit(event, data) {
        if (!this.socket?.connected) {
            throw new Error('Socket not connected');
        }

        this.socket.emit(event, data);
    }

    // Disconnect socket
    disconnect() {
        if (this.socket) {
            // Remove all event listeners
            this.eventHandlers.forEach((handlers, event) => {
                handlers.forEach(handler => {
                    this.socket.off(event, handler);
                });
            });
            this.eventHandlers.clear();

            // Disconnect socket
            this.socket.disconnect();
            this.socket = null;
        }
    }

    // Check connection status
    isConnected() {
        return this.socket?.connected ?? false;
    }

    // Handle reconnection failure
    handleReconnectFailure() {
        console.error('Maximum reconnection attempts reached');
        this.disconnect();

        // Notify the application
        window.dispatchEvent(
            new CustomEvent('socket-reconnect-failed', {
                detail: {
                    message: 'Failed to reconnect to the server. Please refresh the page.',
                },
            })
        );
    }

    // Custom events for WhatsApp
    onWhatsAppMessage(handler) {
        this.on('whatsapp_message_received', handler);
    }

    onMessageStatus(handler) {
        this.on('message_status_update', handler);
    }

    onMediaProcessed(handler) {
        this.on('whatsapp_media_processed', handler);
    }

    onAgentMessageSent(handler) {
        this.on('agent_message_sent', handler);
    }
}

// Create and export singleton instance
export const socketService = new SocketService();

// Export event names for consistency
export const SocketEvents = {
    MESSAGE_RECEIVED: 'whatsapp_message_received',
    STATUS_UPDATE: 'message_status_update',
    MEDIA_PROCESSED: 'whatsapp_media_processed',
    //agent_message_sent
    AGENT_MESSAGE_SENT: 'agent_message_sent',
};