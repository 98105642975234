
//src/components/shared/Loading.js
import React from 'react';

const Loading = ({ size = 'medium', fullScreen = false }) => {
    const sizeClasses = {
        small: 'w-5 h-5',
        medium: 'w-8 h-8',
        large: 'w-12 h-12'
    };

    const spinner = (
        <div
            className={`${sizeClasses[size]} border-4 border-gray-200 border-t-indigo-600 rounded-full animate-spin`}
            role="status"
        >
            <span className="sr-only">Loading...</span>
        </div>
    );

    if (fullScreen) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75">
                {spinner}
            </div>
        );
    }

    return (
        <div className="flex justify-center items-center p-4">
            {spinner}
        </div>
    );
};

export default Loading;