//src/components/chat/ChatHeader.js
import React from 'react';
import { Phone, MoreVertical } from 'lucide-react';

const ChatHeader = ({ currentChat }) => {
    if (!currentChat) return null;

    // Get initials from name or use first character of phone number
    const getInitials = (name) => {
        if (!name) return '#';
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    };

    return (
        <div className="bg-white border-b px-6 py-4 flex items-center justify-between">
            <div className="flex items-center">
                {/* Avatar */}
                <div className="w-10 h-10 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 font-semibold">
                    {getInitials(currentChat.name)}
                </div>

                {/* Contact Info */}
                <div className="ml-4">
                    <h3 className="text-lg font-semibold text-gray-900">
                        {currentChat.name || 'Unknown'}
                    </h3>
                    <div className="flex items-center text-sm text-gray-500">
                        <Phone size={14} className="mr-1" />
                        <span>{currentChat.phone}</span>
                    </div>
                </div>
            </div>

            {/* Actions */}
            <div className="flex items-center space-x-4">
                <button className="p-2 hover:bg-gray-100 rounded-full">
                    <MoreVertical size={20} className="text-gray-500" />
                </button>
            </div>
        </div>
    );
};

export default ChatHeader;