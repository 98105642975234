
//src/services/api.js
const API_BASE_URL = process.env.REACT_APP_API_URL || '';

class ApiService {
    constructor() {
        this.baseUrl = API_BASE_URL;
    }

    //getBaseUrl
    getBaseUrl() {
        return this.baseUrl;
    }

    // Helper method for making authenticated requests
    /*
    async fetchWithAuth(endpoint, options = {}) {
        const token = localStorage.getItem('token');
        console.log('token', token);
        console.log('BaseUrl', this.baseUrl);

        try {
            const response = await fetch(`${this.baseUrl}${endpoint}`, {
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : '',
                    ...options.headers,
                },
            });

            // Handle 401 Unauthorized
            if (response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload();
                throw new Error('Session expired');
            }

            // Parse JSON response
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Request failed');
            }

            return data;
        } catch (error) {
            console.error(`API Error (${endpoint}):`, error);
            throw error;
        }
    }
    */

    async fetchWithAuth(endpoint, options = {}) {
        const token = localStorage.getItem('token');
        const url = this.buildUrl(endpoint, options.params);

        try {
            const response = await fetch(url, {
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : '',
                    ...options.headers,
                },
            });

            if (response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload();
                throw new Error('Session expired');
            }

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Request failed');
            }

            return data;
        } catch (error) {
            console.error(`API Error (${endpoint}):`, error);
            throw error;
        }
    }

    // Auth endpoints
    async login(username, password) {
        return this.fetchWithAuth('/api/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
        });
    }

    //Validate token
    async validateToken(token) {
        return this.fetchWithAuth('/api/validate-token', {
            method: 'POST',
            body: JSON.stringify({ token }),
        });
    }

    async logout() {
        return this.fetchWithAuth('/api/logout', {
            method: 'POST',
        });
    }

    // Conversations endpoints
    async getConversations() {
        return this.fetchWithAuth('/api/agent/conversations');
    }

    async getChatHistory(phone, page = 1, limit = 50) {
        return this.fetchWithAuth(
            `/api/agent/chat/${phone}?page=${page}&limit=${limit}`
        );
    }



    // Messages endpoints
    async sendMessage(recipientPhone, message, recipientName = '') {
        return this.fetchWithAuth('/api/agent/message', {
            method: 'POST',
            body: JSON.stringify({
                recipientPhone,
                recipientName,
                message,
            }),
        });
    }

    async sendMedia(file, recipientPhone, recipientName = '', caption = '') {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('recipientPhone', recipientPhone);
        formData.append('recipientName', recipientName);
        formData.append('caption', caption);
        formData.append('type', this.getFileType(file));

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${this.baseUrl}/api/agent/media`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    // Don't set Content-Type here, let browser set it with boundary
                },
                body: formData,
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Failed to upload media');
            }

            return response.json();
        } catch (error) {
            console.error('Media upload error:', error);
            throw error;
        }
    }

    async markMessagesAsRead(messages) {
        return this.fetchWithAuth('/api/agent/mark-read', {
            method: 'POST',
            body: JSON.stringify({ messages }),
        });
    }

    // Message status endpoints
    async getMessageStatus(messageId) {
        return this.fetchWithAuth(`/api/message-status/${messageId}`);
    }

    async getFailedMessages(timeRange = '24h') {
        return this.fetchWithAuth(`/api/failed-messages?timeRange=${timeRange}`);
    }

    async retryMessage(messageId) {
        return this.fetchWithAuth(`/api/retry-message/${messageId}`, {
            method: 'POST',
        });
    }

    // Helper methods
    getFileType(file) {
        if (file.type.startsWith('image/')) return 'image';
        if (file.type.startsWith('video/')) return 'video';
        if (file.type.startsWith('audio/')) return 'audio';
        return 'document';
    }




    // Dashboard API Methods
    async getReportsSummary(params) {
        return this.fetchWithAuth('/api/reports/summary', {
            method: 'GET',
            params
        });
    }

    async getAnalyticsSentiment(params) {
        return this.fetchWithAuth('/api/analytics/sentiment', {
            method: 'GET',
            params
        });
    }

    async getAnalyticsConversations(params) {
        return this.fetchWithAuth('/api/analytics/conversations', {
            method: 'GET',
            params
        });
    }





    // Metrics and Analytics endpoints
    async getEngagementMetrics(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/engagement?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getMessageVolume(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/messages/volume?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getUserRetention(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/users/retention?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getResponseMetrics(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/messages/response?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getActiveUsers(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/users/active?startDate=${startDate}&endDate=${endDate}`
        );
    }

    async getDailyActivity(startDate, endDate) {
        return this.fetchWithAuth(
            `/api/analytics/activity/daily?startDate=${startDate}&endDate=${endDate}`
        );
    }


    // User Management endpoints
    async getUsers(params = {}) {
        const queryParams = new URLSearchParams();
        if (params.page) queryParams.append('page', params.page);
        if (params.limit) queryParams.append('limit', params.limit);
        if (params.search) queryParams.append('search', params.search);
        if (params.role) queryParams.append('role', params.role);
        //add a query parameter no cache
        queryParams.append('noCache', new Date().getTime());

        return this.fetchWithAuth(`/api/users?${queryParams.toString()}`);
    }


    async createUser(userData) {
        return this.fetchWithAuth('/api/register', {
            method: 'POST',
            body: JSON.stringify(userData)
        });
    }

    async updateUser(userId, userData) {
        return this.fetchWithAuth(`/api/users/${userId}`, {
            method: 'PUT',
            body: JSON.stringify(userData)
        });
    }

    async deleteUser(userId) {
        return this.fetchWithAuth(`/api/users/${userId}`, {
            method: 'DELETE'
        });
    }

    async changeUserPassword(userId, passwords) {
        return this.fetchWithAuth('/api/change-password', {
            method: 'POST',
            body: JSON.stringify(passwords)
        });
    }

    // Helper method to handle URL parameters
    buildUrl(endpoint, params) {
        const url = new URL(`${this.baseUrl}${endpoint}`);
        if (params) {
            Object.keys(params).forEach(key =>
                url.searchParams.append(key, params[key])
            );
        }
        return url.toString();
    }





}

export const apiService = new ApiService();