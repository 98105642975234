// src/components/dashboard/SentimentDashboard.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { apiService } from '../../services/api';
import DateRangePicker from './DateRangePicker';
import { RefreshCcw, MessageSquare, TrendingUp, BarChart2 } from 'lucide-react';
import { Line, Doughnut } from 'react-chartjs-2';
import { subDays } from 'date-fns';
import { format } from 'date-fns';

const SentimentDashboard = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sentimentData, setSentimentData] = useState({
        overall: {
            positive: 0,
            neutral: 0,
            negative: 0
        },
        trend: {
            daily: []
        },
        terms: {
            positive: [],
            negative: []
        }
    });

    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }
        refreshDashboard();
    }, [user]);

    const refreshDashboard = async () => {
        try {
            setLoading(true);
            setError(null);

            const params = {
                startDate: format(startDate, 'yyyy-MM-dd'),
                endDate: format(endDate, 'yyyy-MM-dd HH:mm:ss')
            };

            const response = await apiService.getAnalyticsSentiment(params);
            setSentimentData(response.data);
        } catch (err) {
            setError('Failed to load sentiment analysis data');
            console.error('Sentiment dashboard error:', err);
        } finally {
            setLoading(false);
        }
    };

    const trendChartData = {
        labels: sentimentData.trend.daily?.map(day => format(new Date(day.date), 'MMM d')) || [],
        datasets: [{
            label: 'Sentiment Score',
            data: sentimentData.trend.daily?.map(day => day.average) || [],
            borderColor: 'rgb(59, 130, 246)',
            backgroundColor: 'rgba(59, 130, 246, 0.1)',
            fill: true,
            tension: 0.4
        }]
    };

    const distributionChartData = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [{
            data: [
                sentimentData.overall.positive,
                sentimentData.overall.neutral,
                sentimentData.overall.negative
            ],
            backgroundColor: [
                'rgb(34, 197, 94)',
                'rgb(156, 163, 175)',
                'rgb(239, 68, 68)'
            ]
        }]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };

    const calculatePercentage = (value) => {
        const total = sentimentData.overall.positive +
            sentimentData.overall.neutral +
            sentimentData.overall.negative;
        return total > 0 ? ((value / total) * 100).toFixed(1) : '0.0';
    };

    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Header */}
            <header className="bg-white shadow sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
                        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                            Sentiment Analysis Dashboard
                        </h1>

                        <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                onStartChange={(date) => {
                                    setStartDate(date);
                                    setError(null);
                                }}
                                onEndChange={(date) => {
                                    setEndDate(date);
                                    setError(null);
                                }}
                            />

                            <button
                                onClick={refreshDashboard}
                                disabled={loading}
                                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
                            >
                                <RefreshCcw size={16} className={loading ? 'animate-spin' : ''} />
                                Refresh
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Error Display */}
            {error && (
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        {error}
                        <button
                            onClick={() => setError(null)}
                            className="absolute right-0 top-0 p-4"
                        >
                            ×
                        </button>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <main className="py-6 px-4 sm:px-6 overflow-auto">
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                    </div>
                ) : (
                    <div className="max-w-7xl mx-auto space-y-6">
                        {/* Sentiment Cards */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            <MetricCard
                                title="Positive Sentiment"
                                value={sentimentData.overall.positive}
                                percentage={`${calculatePercentage(sentimentData.overall.positive)}%`}
                                className="bg-green-50 border-green-200"
                                textColor="text-green-800"
                                valueColor="text-green-600"
                            />
                            <MetricCard
                                title="Neutral Sentiment"
                                value={sentimentData.overall.neutral}
                                percentage={`${calculatePercentage(sentimentData.overall.neutral)}%`}
                                className="bg-gray-50 border-gray-200"
                                textColor="text-gray-800"
                                valueColor="text-gray-600"
                            />
                            <MetricCard
                                title="Negative Sentiment"
                                value={sentimentData.overall.negative}
                                percentage={`${calculatePercentage(sentimentData.overall.negative)}%`}
                                className="bg-red-50 border-red-200"
                                textColor="text-red-800"
                                valueColor="text-red-600"
                            />
                        </div>

                        {/* Charts */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <ChartCard title="Sentiment Trend Over Time">
                                <Line data={trendChartData} options={chartOptions} />
                            </ChartCard>
                            <ChartCard title="Sentiment Distribution">
                                <Doughnut
                                    data={distributionChartData}
                                    options={{
                                        ...chartOptions,
                                        plugins: {
                                            ...chartOptions.plugins,
                                            legend: {
                                                position: 'right'
                                            }
                                        }
                                    }}
                                />
                            </ChartCard>
                        </div>

                        {/* Common Terms */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <TermsCard
                                title="Common Positive Terms"
                                terms={sentimentData.terms.positive}
                                type="positive"
                            />
                            <TermsCard
                                title="Common Negative Terms"
                                terms={sentimentData.terms.negative}
                                type="negative"
                            />
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
};

const MetricCard = ({ title, value, percentage, className, textColor, valueColor }) => (
    <div className={`rounded-lg shadow p-6 border transition-transform hover:-translate-y-1 ${className}`}>
        <h3 className={`text-lg font-semibold ${textColor}`}>{title}</h3>
        <div className="mt-2 flex justify-between items-end">
            <div className={`text-3xl font-bold ${valueColor}`}>{value}</div>
            <div className={`text-sm ${valueColor}`}>{percentage}</div>
        </div>
    </div>
);

const ChartCard = ({ title, children }) => (
    <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">{title}</h3>
        <div className="h-[300px]">
            {children}
        </div>
    </div>
);

const TermsCard = ({ title, terms, type }) => {
    const colors = {
        positive: 'bg-green-100 text-green-800 border-green-200',
        negative: 'bg-red-100 text-red-800 border-red-200'
    };

    return (
        <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">{title}</h3>
            <div className="flex flex-wrap gap-2">
                {terms.map((term, index) => (
                    <div
                        key={index}
                        className={`px-3 py-1 rounded-full text-sm border transition-transform hover:scale-105 ${colors[type]}`}
                    >
                        {term.term} <span className="font-semibold">({term.count})</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SentimentDashboard;