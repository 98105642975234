
//src/hooks/useSocket.js
import { useEffect, useRef, useCallback, useState } from 'react';
import { socketService, SocketEvents } from '../services/socket';
//import { useAuth } from './useAuth';
import { useAuth } from '../contexts/AuthContext';

export function useSocket() {
    const { user } = useAuth();
    const [connected, setConnected] = useState(false);
    const [error, setError] = useState(null);
    const socketRef = useRef(null);
    const handlersRef = useRef(new Map());

    // Initialize socket connection
    useEffect(() => {
        console.log("useSocket.js", user?.token);
        if (user?.token) {
            try {
                socketRef.current = socketService.connect(user.token);

                // Setup connection status handlers
                socketRef.current.on('connect', () => {
                    setConnected(true);
                    setError(null);
                });

                socketRef.current.on('disconnect', () => {
                    setConnected(false);
                });

                socketRef.current.on('connect_error', (err) => {
                    setError(err.message);
                    setConnected(false);
                });

            } catch (err) {
                setError(err.message);
            }
        }

        return () => {
            if (socketRef.current) {
                socketService.disconnect();
                socketRef.current = null;
                handlersRef.current.clear();
            }
        };
    }, [user?.token]);

    // Subscribe to events
    const subscribe = useCallback((event, handler) => {
        if (!socketRef.current) {
            console.warn('Socket not initialized');
            return () => { };
        }

        // Store handler reference
        if (!handlersRef.current.has(event)) {
            handlersRef.current.set(event, new Set());
        }
        handlersRef.current.get(event).add(handler);

        // Add socket event listener
        socketRef.current.on(event, handler);

        // Return cleanup function
        return () => {
            handlersRef.current.get(event)?.delete(handler);
            socketRef.current?.off(event, handler);
        };
    }, []);

    // Unsubscribe from events
    const off = useCallback((event, handler) => {
        if (!socketRef.current) return;

        if (handler) {
            // Remove specific handler
            handlersRef.current.get(event)?.delete(handler);
            socketRef.current.off(event, handler);
        } else {
            // Remove all handlers for this event
            const handlers = handlersRef.current.get(event);
            if (handlers) {
                handlers.forEach(h => socketRef.current.off(event, h));
                handlers.clear();
            }
        }
    }, []);

    // Emit events
    const emit = useCallback((event, data) => {
        if (!socketRef.current?.connected) {
            console.warn('Socket not connected');
            return;
        }
        socketRef.current.emit(event, data);
    }, []);

    // WhatsApp specific event subscriptions
    /*
    const onMessage = useCallback((handler) => {
        return subscribe(SocketEvents.MESSAGE_RECEIVED, handler);
    }, [subscribe]);

    const onStatusUpdate = useCallback((handler) => {
        return subscribe(SocketEvents.STATUS_UPDATE, handler);
    }, [subscribe]);

    const onMediaProcessed = useCallback((handler) => {
        return subscribe(SocketEvents.MEDIA_PROCESSED, handler);
    }, [subscribe]);

    //AGENT_MESSAGE_SENT
    const onAgentMessageSent = useCallback((handler) => {
        return subscribe(SocketEvents.AGENT_MESSAGE_SENT, handler);
    }, [subscribe]);
    */



    const onMessage = useCallback((handler) => {
        const cleanup = subscribe(SocketEvents.MESSAGE_RECEIVED, handler);
        return () => {
            cleanup();
            off(SocketEvents.MESSAGE_RECEIVED, handler);
        };
    }, [subscribe, off]);

    const onStatusUpdate = useCallback((handler) => {
        const cleanup = subscribe(SocketEvents.STATUS_UPDATE, handler);
        return () => {
            cleanup();
            off(SocketEvents.STATUS_UPDATE, handler);
        };
    }, [subscribe, off]);

    const onMediaProcessed = useCallback((handler) => {
        const cleanup = subscribe(SocketEvents.MEDIA_PROCESSED, handler);
        return () => {
            cleanup();
            off(SocketEvents.MEDIA_PROCESSED, handler);
        };
    }, [subscribe, off]);

    const onAgentMessageSent = useCallback((handler) => {
        const cleanup = subscribe(SocketEvents.AGENT_MESSAGE_SENT, handler);
        return () => {
            cleanup();
            off(SocketEvents.AGENT_MESSAGE_SENT, handler);
        };
    }, [subscribe, off]);

    // Custom hook for message events
    const useMessageEvent = (handler) => {
        useEffect(() => {
            return onMessage(handler);
        }, [handler]);
    };

    // Custom hook for status update events
    const useStatusEvent = (handler) => {
        useEffect(() => {
            return onStatusUpdate(handler);
        }, [handler]);
    };

    // Custom hook for media processed events
    const useMediaEvent = (handler) => {
        useEffect(() => {
            return onMediaProcessed(handler);
        }, [handler]);
    };

    return {
        connected,
        error,
        subscribe,
        off,
        emit,
        onMessage,
        onStatusUpdate,
        onMediaProcessed,
        useMessageEvent,
        useStatusEvent,
        useMediaEvent,
        onAgentMessageSent,

    };
}

// Helper hook for auto-reconnection
export function useSocketWithReconnect(options = { maxAttempts: 5, delay: 5000 }) {
    const socket = useSocket();
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const reconnectTimeout = useRef(null);

    useEffect(() => {
        if (!socket.connected && reconnectAttempts < options.maxAttempts) {
            reconnectTimeout.current = setTimeout(() => {
                socketService.connect();
                setReconnectAttempts(prev => prev + 1);
            }, options.delay);
        }

        if (socket.connected) {
            setReconnectAttempts(0);
        }

        return () => {
            if (reconnectTimeout.current) {
                clearTimeout(reconnectTimeout.current);
            }
        };
    }, [socket.connected, reconnectAttempts, options.maxAttempts, options.delay]);

    return {
        ...socket,
        reconnectAttempts,
        maxAttempts: options.maxAttempts,
    };
}

// Example usage in components:
/*
function ChatComponent() {
  const socket = useSocket();
  // or with auto-reconnect:
  // const socket = useSocketWithReconnect({ maxAttempts: 3, delay: 3000 });

  // Use the built-in event hooks
  socket.useMessageEvent((message) => {
    console.log('New message:', message);
  });

  socket.useStatusEvent((status) => {
    console.log('Status update:', status);
  });

  // Or use the subscribe method for custom events
  useEffect(() => {
    const unsubscribe = socket.subscribe('custom_event', (data) => {
      console.log('Custom event:', data);
    });

    return unsubscribe;
  }, []);

  // Emit events
  const sendMessage = () => {
    socket.emit('send_message', { text: 'Hello!' });
  };

  return (
    <div>
      {!socket.connected && <div>Connecting...</div>}
      {socket.error && <div>Error: {socket.error}</div>}
      <button onClick={sendMessage}>Send Message</button>
    </div>
  );
}
*/