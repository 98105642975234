// src/utils/dashboardUtils.js

export const formatDuration = (ms) => {
    if (!ms) return '0s';

    const seconds = Math.floor(ms / 1000);
    if (seconds < 60) return `${seconds}s`;

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes}m`;

    const hours = Math.floor(minutes / 60);
    return `${hours}h ${minutes % 60}m`;
};

export const calculateDeliveryRate = (statusData) => {
    if (!statusData || !statusData.length) return 0;

    const total = statusData.reduce((acc, status) => acc + status.count, 0);
    const delivered = statusData
        .filter(s => ['delivered', 'read'].includes(s.status))
        .reduce((acc, status) => acc + status.count, 0);

    return total > 0 ? (delivered / total) * 100 : 0;
};