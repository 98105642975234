// src/components/dashboard/WhatsAppDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { formatDuration, calculateDeliveryRate } from '../../utils/dashboardUtils';
import { MessageVolumeChart, MessageTypeChart, SentimentChart } from './charts';
import { ActivityPatternChart } from './charts/ActivityPatternChart';
import UserActivityTable from './UserActivityTable';

import {
    Calendar,
    RefreshCcw,
    MessageSquare,
    Users,
    Clock,
    CheckCircle,
    Menu
} from 'lucide-react';
import { apiService } from '../../services/api';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line, Doughnut, Bar } from 'react-chartjs-2';  // Fixed import
import { format, subDays } from 'date-fns';
import DateRangePicker from './DateRangePicker';
// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
);



const WhatsAppDashboard = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dashboardData, setDashboardData] = useState({
        messageVolume: [],
        messageTypes: [],
        sentiment: {},
        activeUsers: [],
        responseTime: {},
        deliveryStatus: [],
        interactionPatterns: {}
    });

    // Only fetch data on initial load
    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }
        refreshDashboard();
    }, [user]); // Remove date dependencies

    const refreshDashboard = async () => {
        if (!startDate || !endDate) {
            setError('Please select both start and end dates');
            return;
        }

        if (startDate > endDate) {
            setError('Start date must be before end date');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const params = {
                startDate: format(startDate, 'yyyy-MM-dd'),
                endDate: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
            };

            console.log('Fetching data with params:', params);

            const [summary, sentiment, conversations] = await Promise.all([
                apiService.getReportsSummary(params),
                apiService.getAnalyticsSentiment(params),
                apiService.getAnalyticsConversations(params)
            ]);

            setDashboardData({
                ...summary.data,
                sentiment: sentiment.data,
                conversations: conversations.data,
                hourlyActivity: conversations.data?.interactionPatterns?.hourlyDistribution ||
                    conversations.data?.hourlyActivity ||
                    Array(24).fill(0)
            });
        } catch (err) {
            setError('Failed to load dashboard data');
            console.error('Dashboard error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="h-screen flex flex-col bg-gray-50">
            {/* Fixed Header */}
            <header className="bg-white shadow sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    {/* Desktop and Mobile Layout */}
                    <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center sm:space-y-0">
                        {/* Title */}
                        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                            WhatsApp Analytics Dashboard
                        </h1>

                        {/* Controls */}
                        <div className="flex flex-col space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                            {/* Date Picker Group */}
                            <div className="grid grid-cols-2 gap-2 sm:flex sm:items-center">
                                <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    onStartChange={(date) => {
                                        setStartDate(date);
                                        setError(null);
                                    }}
                                    onEndChange={(date) => {
                                        setEndDate(date);
                                        setError(null);
                                    }}
                                />
                            </div>

                            {/* Refresh Button */}
                            <button
                                onClick={refreshDashboard}
                                disabled={loading}
                                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
                            >
                                <RefreshCcw size={16} className={loading ? 'animate-spin' : ''} />
                                Refresh
                            </button>
                        </div>
                    </div>
                </div>
            </header>


            {/* Error Display */}
            {error && (
                <div className="sticky top-[88px] z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        <span className="block sm:inline">{error}</span>
                        <span
                            className="absolute top-0 bottom-0 right-0 px-4 py-3"
                            onClick={() => setError(null)}
                        >
                            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <title>Close</title>
                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                        </span>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <main className=" py-6 px-4 sm:px-6">
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                    </div>
                ) : error ? (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        {error}
                    </div>
                ) : (
                    <>
                        {/* Key Metrics */}
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
                            <MetricCard
                                title="Total Messages"
                                value={dashboardData.messageVolume.reduce((acc, day) => acc + day.total, 0)}
                                icon={<MessageSquare className="text-blue-500" />}
                            />
                            <MetricCard
                                title="Active Users"
                                value={dashboardData.activeUsers.length}
                                icon={<Users className="text-green-500" />}
                            />
                            <MetricCard
                                title="Avg Response Time"
                                value={formatDuration(dashboardData.responseTime.averageResponseTime)}
                                icon={<Clock className="text-purple-500" />}
                            />
                            <MetricCard
                                title="Delivery Rate"
                                value={`${calculateDeliveryRate(dashboardData.deliveryStatus).toFixed(1)}%`}
                                icon={<CheckCircle className="text-orange-500" />}
                            />
                        </div>

                        {/* Charts */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                            <ChartCard title="Message Volume Trend">
                                <MessageVolumeChart data={dashboardData.messageVolume} />
                            </ChartCard>
                            <ChartCard title="Message Type Distribution">
                                <MessageTypeChart data={dashboardData.messageTypes} />
                            </ChartCard>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                            <ChartCard title="Sentiment Analysis">
                                <SentimentChart data={dashboardData.sentiment} />
                            </ChartCard>
                            <ChartCard title="Daily Activity Pattern">
                                <ActivityPatternChart data={dashboardData.conversations} />
                            </ChartCard>
                        </div>

                        {/* User Activity Table */}
                        <div className="bg-white rounded-lg shadow">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg font-medium text-gray-900">Top Active Users</h3>
                            </div>
                            <UserActivityTable users={dashboardData.conversations?.userMessages || []} />
                        </div>
                    </>
                )}
            </main>
        </div>
    );
};

// Metric Card Component
const MetricCard = ({ title, value, icon }) => (
    <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center justify-between">
            <div>
                <p className="text-sm font-medium text-gray-600">{title}</p>
                <p className="mt-2 text-3xl font-semibold text-gray-900">{value}</p>
            </div>
            <div className="p-3 bg-gray-50 rounded-full">{icon}</div>
        </div>
    </div>
);

// Chart Card Component
const ChartCard = ({ title, children }) => (
    <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">{title}</h3>
        <div className="h-[300px]">
            {children}
        </div>
    </div>
);

export default WhatsAppDashboard;