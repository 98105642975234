//src/components/chat/ChatInput.js
import React, { useState, useRef } from 'react';
import { Send, Paperclip, Image, X } from 'lucide-react';

const ChatInput = ({ onSendMessage, onSendMedia, disabled }) => {
    const [message, setMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const fileInputRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim() || selectedFile) {
            if (selectedFile) {
                onSendMedia(selectedFile, message);
                setSelectedFile(null);
                setPreviewUrl('');
            } else {
                onSendMessage(message);
            }
            setMessage('');
        }
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);

            // Create preview for images
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => setPreviewUrl(e.target.result);
                reader.readAsDataURL(file);
            }
        }
    };

    const clearSelectedFile = () => {
        setSelectedFile(null);
        setPreviewUrl('');
        fileInputRef.current.value = '';
    };

    return (
        <div className="bg-white border-t p-4">
            {/* Media Preview */}
            {selectedFile && (
                <div className="mb-4 relative inline-block">
                    {previewUrl ? (
                        <img
                            src={previewUrl}
                            alt="Preview"
                            className="h-20 w-20 object-cover rounded"
                        />
                    ) : (
                        <div className="h-20 w-20 bg-gray-100 rounded flex items-center justify-center">
                            <Paperclip className="text-gray-400" />
                        </div>
                    )}
                    <button
                        onClick={clearSelectedFile}
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                    >
                        <X size={14} />
                    </button>
                </div>
            )}

            {/* Input Form */}
            <form onSubmit={handleSubmit} className="flex items-center space-x-4">
                {/* File Input */}
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    className="hidden"
                    accept="image/*,video/*,audio/*,.pdf,.doc,.docx"
                />

                {/* Attachment Button */}
                <button
                    type="button"
                    onClick={() => fileInputRef.current.click()}
                    className="p-2 text-gray-500 hover:text-gray-600 hover:bg-gray-100 rounded-full"
                >
                    <Paperclip size={20} />
                </button>

                {/* Message Input */}
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type a message..."
                    disabled={disabled}
                    className="flex-1 border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                />

                {/* Send Button */}
                <button
                    type="submit"
                    disabled={disabled || (!message.trim() && !selectedFile)}
                    className={`p-2 rounded-full ${disabled || (!message.trim() && !selectedFile)
                        ? 'bg-gray-100 text-gray-400'
                        : 'bg-indigo-600 text-white hover:bg-indigo-700'
                        }`}
                >
                    <Send size={20} />
                </button>
            </form>
        </div>
    );
};

export default ChatInput;