//src/components/chat/ConversationItem.js
import React from 'react';
import { formatDistanceToNow } from 'date-fns';

const ConversationItem = ({ conversation, isActive, onClick }) => {
    const getInitials = (name) => {
        if (!name) return '#';
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    };

    const formatLastMessage = (message, type) => {
        if (!message) return 'No messages yet';

        switch (type) {
            case 'image_message':
                return '📷 Photo';
            case 'video_message':
                return '🎥 Video';
            case 'audio_message':
                return '🎵 Audio';
            case 'document_message':
                return '📄 Document';
            default:
                return message;
        }
    };

    return (
        <div
            onClick={onClick}
            className={`
        px-4 py-3 border-b cursor-pointer transition-colors
        ${isActive ? 'bg-indigo-50' : 'hover:bg-gray-50'}
      `}
        >
            <div className="flex items-center space-x-3">
                {/* Avatar */}
                <div className="relative flex-shrink-0">
                    <div className={`
            w-12 h-12 rounded-full flex items-center justify-center
            ${isActive ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-600'}
            font-semibold text-sm
          `}>
                        {getInitials(conversation.name)}
                    </div>
                    {conversation.unreadCount > 0 && (
                        <div className="absolute -top-1 -right-1 w-5 h-5 bg-indigo-600 rounded-full flex items-center justify-center">
                            <span className="text-xs text-white">{conversation.unreadCount}</span>
                        </div>
                    )}
                </div>

                {/* Content */}
                <div className="flex-1 min-w-0">
                    <div className="flex justify-between items-baseline">
                        <h3 className="text-sm font-semibold text-gray-900 truncate">
                            {conversation.name || conversation.phone}
                        </h3>
                        {conversation.lastMessageTime && (
                            <span className="text-xs text-gray-500">
                                {formatDistanceToNow(new Date(conversation.lastMessageTime), { addSuffix: true })}
                            </span>
                        )}
                    </div>
                    <p className="text-sm text-gray-500 truncate">
                        {formatLastMessage(conversation.lastMessage, conversation.lastMessageType)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ConversationItem;