// src/components/dashboard/charts/ActivityPatternChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';

export const ActivityPatternChart = ({ data }) => {
    // Create labels for 24 hours
    const hourLabels = Array.from({ length: 24 }, (_, i) =>
        `${String(i).padStart(2, '0')}:00`
    );

    // Process hourly data
    let hourlyData = Array(24).fill(0);

    // Check different possible data structures
    if (data?.interactionPatterns?.hourlyDistribution) {
        hourlyData = data.interactionPatterns.hourlyDistribution;
    } else if (data?.hourlyActivity) {
        hourlyData = data.hourlyActivity;
    } else if (Array.isArray(data)) {
        hourlyData = data;
    }

    const chartData = {
        labels: hourLabels,
        datasets: [{
            label: 'Message Volume',
            data: hourlyData,
            borderColor: 'rgb(147, 51, 234)',
            backgroundColor: 'rgba(147, 51, 234, 0.1)',
            fill: true,
            tension: 0.4,
            pointRadius: 4,
            pointHoverRadius: 6,
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: (tooltipItems) => {
                        return `Time: ${tooltipItems[0].label}`;
                    },
                    label: (context) => {
                        return `Messages: ${context.raw}`;
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    stepSize: 1,
                }
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45,
                }
            }
        }
    };

    return <Line data={chartData} options={options} />;
};

export default ActivityPatternChart;